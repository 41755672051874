import React from "react"
import { graphql } from "gatsby";
import { Member } from '../components/member'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `MEMBER`, path: `member` },
]

export default function Home({ data }) {
  const { ogpImage } = data;

  return (
    <Member breadcrumbsData={breadcrumbsData} ogpImage={ogpImage}/>
  )
}

export const query = graphql`
  query {
    ogpImage: file(relativePath: { eq: "images/group_photo.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`