import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import Layout from "../../template/Layout"
import styled from 'styled-components';
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../AppConst";
import { ogpUrl } from "../../utlis/ImageUtil"
import { MemberItem } from "./common/MemberItem"
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { SectionTitle } from "../common/SectionTitle";
import { SpWrapper } from "../common/SpWrapper";

/**
 * Element & Styles
 */
const Node = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  width: 100%;
  max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  padding: 0 0 160px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 0 0 80px;
  }
`
const MemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0 40px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    gap: 0 23px;
  }
  /** 以下、メンバー増減時の調整用 */
  &::after {
    display: block;
    content:"";
    width: ${(RESPONSIVE_STYLES.MAX_WIDTH - 40 * 2)/3}px;
  }
  &::before {
    content: "";
    order: 1;
    width: ${(RESPONSIVE_STYLES.MAX_WIDTH - 40 * 2)/3}px;
  }
`

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  ogpImage: any
}

export const Member: React.FC<Props> = ({
  breadcrumbsData,
  ogpImage
}) => {

  const { site: { siteMetadata: { member } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            position
            pathname
            alt
            member_index_filename
            displayed_member
            color
          }
        }
      }
    }
  `);

  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="メンバー | ネクストライブ株式会社"
        description="ネクストライブ株式会社のメンバーページです。ネクストライブで働く仲間の一覧です。"
        img={ogpUrl(process.env.NODE_ENV, ogpImage.childImageSharp.fluid)}
      />
      <Node>
        <SpWrapper>
          <SectionTitle title="member" subTitle="メンバー" />
          <MemberContainer>
            {member.map((item, index) =>{
              if (!item.displayed_member) {
                return <></>;
              }
              return <MemberItem
                        key={index}
                        position={item.position}
                        name={item.name}
                        filename={item.member_index_filename}
                        pathname={item.pathname}
                        color={item.color}
                        alt={item.alt}
                     />
            })}
          </MemberContainer>
        </SpWrapper>
      </Node>
    </Layout>
  )
}
